<template>
<!-- <section id="heroSection" class="heroSection">
  <div class="heroSectionDiv">
    <div class="heroContainer">
      <div class="flex">

      </div>
      <div class="heroImg">
        <div class="heroImg-content">
          <img class="hero-img-real" src="../assets/ascii-sercannaya.webp" alt="sercan_naya" draggable="false" >
        </div>
      </div>
    </div>
  </div>
</section> -->
<section class="container section-1">
  <div class="heroSectionDiv">
    <div class="heroContainer slogan">
      <div class="flex">
        <h1 class="company-title heading">
          <span>full stack </span>
          <span>web developer</span>
          <span>based in Turkey</span>
        </h1>
      </div>
    </div>
  </div>
    <img 
      class="heroImg"
      src="../assets/ascii-sercannaya.webp"
      alt="sercan_naya"
      data-aos="fade-right"
      data-aos-delay="1500"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
</section>
</template>

<script>
export default {

}
</script>

<style>
img {
  max-width: 100%;
  display: block;
}

span {
  color: #FFF;
  line-height: 1.35;
  padding: 6px;
}

.heroImg {
  bottom: 0;
  position: absolute;
  width: 100vh;
  right: 0;
}

.section-1 {
  display: grid;
  height: 100vh;
}

.slogan .company-title {
  margin-top: 2rem;
}

.slogan {
  margin-top: 1rem;
}

.flex {
  display: flex;
  flex-direction: column; 
  width: 100%; 
  justify-content: center; 
  align-items: flex-start; 
  padding: 2rem; 
}

@media (min-width: 1024px) { 
  .flex {
    width: 33.333333%; 
  }
}

.heading {
  font-size: 3.5rem;
  position: absolute;
  left: 7%;
  top: 15rem;
  z-index: 10;
  overflow: hidden;
}

.heading span{
  position: relative;
  overflow: hidden;
  display: block;
  line-height: 1.2;
}

.heading span::after{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: white;
  animation: a-ltr-after 2s cubic-bezier(.77,0,.18,1) forwards;
  transform: translateX(-101%);
}

.heading span::before{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #000;
  animation: a-ltr-before 2s cubic-bezier(.77,0,.18,1) forwards;
  transform: translateX(0);
}

.heading span:nth-of-type(1)::before,
.heading span:nth-of-type(1)::after{
  animation-delay: 1s;
}

.heading span:nth-of-type(2)::before,
.heading span:nth-of-type(2)::after{
  animation-delay: 1.1s;
}

.heading span:nth-of-type(3)::before,
.heading span:nth-of-type(3)::after{
  animation-delay: 1.2s;
}

@keyframes a-ltr-after{
  0% {transform: translateX(-100%)}
  100% {transform: translateX(101%)}
}

@keyframes a-ltr-before{
  0% {transform: translateX(0)}
  100% {transform: translateX(200%)}
}

/* ===== Breakpoints ===== */

/* smartphones, iPhone, portrait 480x320 phones */ 
@media only screen and (min-width: 320px) { 
  .heading {
    font-size: 1.8rem;
    transform: translateY(-90%) translateX(1%);
    align-items: center;
    text-align: center;
    font-weight: bolder;
  }

  .section-1 {
    padding-top: 300px;
    overflow: hidden;
  }

  .heroImg {
    scale: 1.3;
  }
}

@media only screen and (min-width: 375px) { 
  .heading {
    font-size: 1.8rem;
    transform: translateY(-90%) translateX(7%);
    align-items: center;
    text-align: center;
    font-weight: bolder;
  }

  .section-1 {
    padding-top: 300px;
    overflow: hidden;
  }

  .heroImg {
    scale: 1.3;
  }
}

@media only screen and (min-width: 390px) { 
  .heading {
    font-size: 1.9rem;
    transform: translateY(-90%) translateX(10%);
    align-items: center;
    text-align: center;
    font-weight: bolder;
  }

  .section-1 {
    padding-top: 300px;
    overflow: hidden;
  }

  .heroImg {
    scale: 1.3;
  }
}

/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
@media only screen and (min-width: 425px) { 
  .heading {
    font-size: 2.5rem;
    transform: translateY(-85%) translateX(0%);
    align-items: center;
    text-align: center;
    font-weight: bolder;
  }

  .section-1 {
    padding-top: 195px;
    overflow: hidden;
  }

  .heroImg {
    scale: 1;
  }
}

@media only screen and (min-width: 525px) { 
  .heading {
    font-size: 2.5rem;
    transform: translateY(-90%) translateX(10%);
    align-items: center;
    text-align: center;
    font-weight: bolder;
  }

  .section-1 {
    padding-top: 195px;
    overflow: hidden;
  }

  .heroImg {
    scale: 1;
  }
}

/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
@media only screen and (min-width:641px) { 
  .heading {
    font-size: 3rem;
    transform: translateY(-15%) translateX(15%);
    align-items: center;
    text-align: center;
    font-weight: bolder;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .section-1 {
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding-top: 15px;
  }

  .heroImg {
    scale: 1;
  }
}

@media only screen and (min-width: 768px) { 
  .heading {
    font-size: 3rem;
    transform: translateY(-15%) translateX(25%);
    align-items: center;
    text-align: center;
    font-weight: bolder;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
  }

  .section-1 {
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .heroImg {
    scale: 1;
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */ 
@media only screen and (min-width:961px) { 
  .heading {
    font-size: 3rem;
    transform: translateY(-45%) translateX(0%);
    align-items: left;
    text-align: left;
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: black;
  }

  .section-1 {
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    padding: 8rem 2rem 0rem 0rem;
  }
}

/* big landscape tablets, laptops, and desktops */ 
@media only screen and (min-width: 1025px) { 
  .heading {
    font-size: 3rem;
    transform: translateY(-40%) translateX(0%);
    align-items: left;
    text-align: left;
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: black;
  }

  .section-1 {
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    padding: 8rem 3rem 0rem 0rem;
  }
}

@media only screen and (min-width: 1281px) { 
  /* hi-res laptops and desktops */ 
  .heading {
    font-size: 3rem;
    transform: translateY(-20%) translateX(-5%);
    align-items: left;
    text-align: left;
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: black;
  }

  .section-1 {
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    padding: 6rem 3rem 0rem 0rem;
  }
}

@media only screen and (min-width: 1440px) { 
  /* hi-res laptops and desktops */ 
  .heading {
    font-size: 4rem;
    transform: translateY(0%) translateX(-5%);
    align-items: left;
    text-align: left;
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: black;
  }

  .section-1 {
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    padding: 6rem 3rem 0rem 0rem;
  }
}

@media only screen and (min-width: 2560px) { 
  .heading {
    font-size: 7rem;
    transform: translateY(30%) translateX(0%);
    align-items: left;
    text-align: left;
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: black;
  }

  .section-1 {
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    padding: 10rem 5rem 0rem 0rem;
  }
}

@media only screen and (min-width: 3840px) { 
  .heading {
    font-size: 10rem;
    transform: translateY(50%) translateX(0%);
    align-items: left;
    text-align: left;
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: black;
  }

  .section-1 {
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    padding: 10rem 5rem 0rem 0rem;
  }
}

</style>