<template>
  <div class="logo">
    <router-link to="/">
      <img
        class="logo" 
        src="../assets/sercannaya-logo.png" 
        alt="sercan-naya-logo" 
        ondragstart="return false;" 
        ondrop="return false;" 
      >
    </router-link>
  </div>
</template>

<script>
</script>

<style>
.logo {  
  position: fixed;
  top: 5vh;
  left: 5vw;
  z-index: 1000;
  display: block;
  width: 4em;
  height: 4em;
  outline: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  mix-blend-mode: difference;
}

/* ===== Breakpoints ===== */
@media only screen and (min-width: 2560px) { 
  .logo {
    width: 8em;
    height: 8em;
  }
}

@media only screen and (min-width: 3840px) { 
  .logo {
    width: 12em;
    height: 12em;
  }
}
</style>