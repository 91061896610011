<template>
  <section id="aboutSection">
    <div class="section-bg-light">
      <div class="padding-top-bottom-200">
        <div class="about-section-container">
          <div class="ten-columns padding-top-20 padding-bottom-20">
            <div class="text-color-black intro-text" data-aos="fade-down" data-aos-duration="1000">
              I'm a full-time <b>Full Stack Developer</b> based in <b>Turkey</b>.
              I mainly work in web development. In the last <b>3 years</b>, 
              I have designed and developed highly responsive websites, 
              and e-commerce solutions using advanced technologies as a <b>Freelancer Full Stack Developer</b>. 
              Additionally, I have consistently met client expectations and project milestones while working 
              effectively in a deadline-driven environment.
              <br>
              <br>
              I Designed and developed over 20 web pages using <b>Vue.js(2x, 3x)</b>, 
              Vuetify, Nuxt.js, <b>Node.js</b>, Express.js, Axios, <b>JavaScript</b>, MongoDB, Firebase, HTML5, CSS-SCSS, 
              Bootstrap, Tailwind, GSAP. In addition provided my clients with logo and 
              brand design services using Photoshop, Figma and DesignXD.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style>
.padding-top-bottom-200 {
  padding-top: 200px;
  padding-bottom: 200px;
}

.padding-top-200 {
  padding-top: 200px;
}

.padding-bottom-200 {
  padding-bottom: 200px;
}

.about-section-container {
  width: calc(100% - 80px);
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 999px) {
  .about-section-container {
    width: calc(100% - 40px);
  }
}

@media only screen and (max-width: 549px) {
  .about-section-container {
    width: calc(100% - 20px);
  }
}

.ten-columns {
  box-sizing: border-box;
  flex-basis: 83.33%;
}

@media only screen and (max-width: 767px) {
  .ten-columns {
    flex-basis: 100%;
    margin-left: 0;
  }
}

.section-bg-light {
  background-color: #f5f5f5;
  letter-spacing: 0.04em;
}

.blog-content-bg {
  background-color: #f0f0f0;
}

.text-color-black,
.text-color-black:before {
  color: #111517 !important;;

}

.intro-text {
  font-size: 1.8em;
  text-align: left;
  line-height: 1.5em;
  font-weight: 400;
}

@media only screen and (max-width: 999px) {
  .intro-text {
    font-size: 1.4em;
    text-align: center;
    line-height: 1.5em;
    margin: 0px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .intro-text {
    font-size: 1.4em!important;
    text-align: left;
    line-height: 1.4em;
  }
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(-20%);
  transition: all 1s;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}


/* ===== Breakpoints ===== */
@media only screen and (min-width: 768px) { 
  .intro-text {
    text-align: left;
  }
}

@media only screen and (min-width: 2560px) { 
  .padding-top-bottom-200 {
    padding-top: 400px;
    padding-bottom: 400px;
  }

  .about-section-container {
    max-width: 2000px;
  }
  .intro-text {
    font-size: 4em;
  }
}

@media only screen and (min-width: 3840px) { 
  .padding-top-bottom-200 {
    padding-top: 500px;
    padding-bottom: 500px;
  }

  .about-section-container {
    max-width: 3000px;
  }
  .intro-text {
    font-size: 5em;
  }
}
</style>