<template>
  <router-view/>
  <div :class="[ 'g-cursor', { 'g-cursor_hover': hover }, {'g-cursor_hide': hideCursor} ]">
    <div :style="cursorCircle" class="g-cursor__circle"></div>
    <div class="g-cursor__point" ref="point" :style="cursorPoint"></div>
  </div>
</template>

<script>
export default{
  data() { 
    return{
      xChild: 0,
      yChild: 0,
      xParent: 0,
      yParent: 0,
      hover: false,
      hideCursor: true
    }
  },
  computed: {
    cursorCircle() {
      return `transform: translateX(${this.xParent}px)
                         translateY(${this.yParent}px) 
                         translateY(0)
                         translate3d(0, 0, 0);`
    },
    cursorPoint() {
      return `transform: translateX(${this.xChild - 3}px) 
                         translateY(${this.yChild - 3}px) 
                         translateZ(0) 
                         translate3d(0, 0, 0);`
    }
  },
  methods: {
    moveCursor(e) {
      this.xChild = e.clientX;
      this.yChild = e.clientY;
      setTimeout(() => {
        this.xParent = e.clientX - 15;
        this.yParent = e.clientY - 14;
      }, 100);
    }
  },
  mounted() {
    /* Custom Cursor */
    document.addEventListener("mousemove", this.moveCursor);
    document.addEventListener('mouseleave', () => {
      this.hideCursor = true;
    });
    document.addEventListener('mouseenter', () => {
      this.hideCursor = false;
    });

    // Console Log Message
    var styles = [
      "background-image: linear-gradient(43deg, #4158D0 0%, #202020 50%, #9e2a2b 100%); padding:5px 5px; color: #ffffff; font-size: xx-large; border-radius: 5px"
    ].join(';');
    console.log('%c Hi dev! What are u looking for? 👀', styles);

    document.addEventListener('wheel', event => {
      const { ctrlKey } = event
      if (ctrlKey) {
          event.preventDefault();
          return
      }
    }, { passive: false })
  },
}
</script>

<style lang="scss">
*,
*::before,
*::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.g-cursor {
  &_hide {
    opacity: 0;
    width: 60px;
    height: 60px;
    transition: width .1s ease,
      height .1s ease,
      opacity .1s ease;
  }

  &__circle {
    pointer-events: none;
    user-select: none;
    top: 9px;
    left: 10px;
    position: fixed;
    width: 2em;
    height: 2em;
    border: 2px solid #fff;
    border-radius: 0px 50px 50px 50px;
    z-index: 9999;
    backface-visibility: hidden;
    transition: opacity .1s ease;
    mix-blend-mode: difference;
  }

  &__point {
    top: 0;
    left: 0;
    position: fixed;
    width: 1.5em;
    height: 1.5em;
    pointer-events: none;
    user-select: none;
    border-radius: 0px 50px 50px 50px;
    transform: translate(-50%,-50%);
    background: #fff;
    z-index: 9999;
    backface-visibility: hidden;
    will-change: transform;
    mix-blend-mode: difference;
  }

  &_hover {
    .g-cursor__circle {
      opacity: 0;
      width: 60px;
      height: 60px;
      transition: width .1s ease,
        height .1s ease,
        opacity .1s ease;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #fff;
}

::selection {
  background: #000;
  color: #fff;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #000;
  font-family: "Verdana";
  font-weight: bold;
  overflow-x: hidden; 
}

@media only screen and (max-width: 1023px) { 
  .g-cursor {
    display: none;
    visibility: hidden;
  }
}
</style>
