<template>
	<section id="contactSection">
		<div class="section-bg-light">
			<div class="padding-top-bottom-200">
				<div class="text-button">
					<div id="contact-section-container">
						<span id="text1"></span>
						<span id="text2"></span>
					</div>
					<svg id="filters">
						<defs>
							<filter id="threshold">
								<feColorMatrix in="SourceGraphic"
									type="matrix"
									values="1 0 0 0 0
													0 1 0 0 0
													0 0 1 0 0
													0 0 0 255 -140" 
								/>
							</filter>
						</defs>
					</svg>
					<li class="content__item">
						<button class="button button-narvi" onclick="window.open('mailto:sercannayaa@gmail.com')">
							<span>
								<span class="button-text">
									Contact Me!
								</span>
							</span>
						</button>
					</li>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {

  mounted() {
    /*
	This pen cleverly utilizes SVG filters to create a "Morphing Text" effect. Essentially, it layers 2 text elements on top of each other, and blurs them depending on which text element should be more visible. Once the blurring is applied, both texts are fed through a threshold filter together, which produces the "gooey" effect. Check the CSS - Comment the #contact-section-container rule's filter out to see how the blurring works!
*/

const elts = {
	text1: document.getElementById("text1"),
	text2: document.getElementById("text2")
};

// The strings to morph between. You can change these to anything you want!
const texts = [
	"Do you have an idea?",
	"Need help with your project?",
	"Do you need a website?",
	"Want to discuss your project? ",
	"Do you need a design?",
	"Then contact me.",
];

// Controls the speed of morphing.
const morphTime = 1;
const cooldownTime = 1;

let textIndex = texts.length - 1;
let time = new Date();
let morph = 0;
let cooldown = cooldownTime;

elts.text1.textContent = texts[textIndex % texts.length];
elts.text2.textContent = texts[(textIndex + 1) % texts.length];

function doMorph() {
	morph -= cooldown;
	cooldown = 0;
	
	let fraction = morph / morphTime;
	
	if (fraction > 1) {
		cooldown = cooldownTime;
		fraction = 1;
	}
	
	setMorph(fraction);
}

// A lot of the magic happens here, this is what applies the blur filter to the text.
function setMorph(fraction) {
	// fraction = Math.cos(fraction * Math.PI) / -2 + .5;
	
	elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
	elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;
	
	fraction = 1 - fraction;
	elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
	elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;
	
	elts.text1.textContent = texts[textIndex % texts.length];
	elts.text2.textContent = texts[(textIndex + 1) % texts.length];
}

function doCooldown() {
	morph = 0;
	
	elts.text2.style.filter = "";
	elts.text2.style.opacity = "100%";
	
	elts.text1.style.filter = "";
	elts.text1.style.opacity = "0%";
}

// Animation loop, which is called every frame.
function animate() {
	requestAnimationFrame(animate);
	
	let newTime = new Date();
	let shouldIncrementIndex = cooldown > 0;
	let dt = (newTime - time) / 1000;
	time = newTime;
	
	cooldown -= dt;
	
	if (cooldown <= 0) {
		if (shouldIncrementIndex) {
			textIndex++;
		}
		
		doMorph();
	} else {
		doCooldown();
	}
}

// Start the animation.
animate();
  }
}
</script>

<style lang="scss">
.text-button {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(2, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	align-items: center;
}

#contact-section-container {
	/* Center the text in the viewport. */
  /* This filter is a lot of the magic, try commenting it out to see how the morphing works! */
	filter: url(#threshold) blur(0.6px);
}

/* Your average text styling */
#text1, #text2 {
	position: absolute;
	width: 100%;
	display: inline-block;
  color: #000;
	font-family: 'Verdana', sans-serif;
  font-weight: bold;
	font-size: 50pt;
	text-align: center;
	user-select: none;
}

.content__item {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	counter-increment: itemcounter;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	pointer-events: none;
	z-index: 11;
}

.button {
	pointer-events: auto;
	background: #000;
	border: none;
	padding: 1.5rem 3rem;
	font-family: inherit;
	font-size: 20pt;
	position: relative;
	display: inline-block;
}

.button::before,
.button::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.button-narvi {
	font-weight: bold;
	background: none;
}

.button-narvi::before {
	content: '';
	z-index: -1;
  color: #fff;
	background: #000;
	-webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 70%, 85% 70%, 80% 70%, 75% 70%, 0 70%);
	clip-path: polygon(0% 0%, 100% 0%, 100% 70%, 85% 70%, 80% 70%, 75% 70%, 0 70%);
	transition: clip-path 0.3s cubic-bezier(0.7, 0, 0.2, 1), -webkit-clip-path 0.3s cubic-bezier(0.7, 0, 0.2, 1), transform 0.3s ease;
}

.button-narvi:hover::before {
	transform: translate3d(0,-10px,0);
	-webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 70%, 85% 70%, 86% 100%, 75% 70%, 0 70%);
	clip-path: polygon(0% 0%, 100% 0%, 100% 70%, 85% 70%, 86% 100%, 75% 70%, 0 70%);
}

.button-narvi span {
  color: #fff;
  font-family: Verdana, sans-serif;
	display: block;
	position: relative;
}

.button-narvi > span {
	transition: transform 0.3s;
	transform: translate3d(0,-0.556rem,0);
	z-index: 1;
	overflow: hidden;
}

.button-narvi:hover > span {
	transform: translate3d(0,-1.111rem,0);
}

.button-narvi:hover > span > span {
	animation: MoveUpInitial 0.15s forwards, MoveUpEnd 0.15s forwards 0.15s;
}

.button-text {
	height: 60px;
}


/* ===== Breakpoints ===== */

@media only screen and (min-width: 320px) and (max-width: 767px) { 
	#text1, #text2  {
		font-size: 30pt;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1439px) { 
	#text1, #text2  {
		font-size: 40pt;
	}
}


@media only screen and (min-width: 2560px) and (max-width: 3839px) { 
	#text1, #text2  {
		font-size: 90pt;
	}

	.button {
		margin-top: 100px;
		font-size: 50pt;
	}

	.button-text {
		height: 150px;
	}
}

@media only screen and (min-width: 3840px) { 
	#text1, #text2  {
		font-size: 140pt;
	}

	.button {
		margin-top: 250px;
		font-size: 90pt;
	}

	.button-text {
		height: 250px;
	}
}

</style>