<template>
  <div class="home">
    <PagePreloader />
    <NavigationBar />
    <HeroSection />
    <AboutSection />
    <WorkSection />
    <ContactSection />
    <FooterSection />
  </div>
</template>

<script>
// @ is an alias to /src
import PagePreloader from '@/components/PagePreloader.vue'
import NavigationBar from '@/components/NavigationBar.vue'
import HeroSection from '@/components/HeroSection.vue'
import AboutSection from '@/components/AboutSection.vue'
import WorkSection from '@/components/WorkSection.vue'
import ContactSection from '@/components/ContactSection.vue'
import FooterSection from '@/components/FooterSection.vue'

export default {
  name: 'HomeView',
  components: {
    PagePreloader,
    NavigationBar,
    HeroSection,
    AboutSection,
    WorkSection,
    ContactSection,
    FooterSection
}
}
</script>