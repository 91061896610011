<template>
<footer class="footer">
  <div class="footer_container">
    <div class="footer_top">
      <div>
        <div class="footer_title">Site Map</div>
        <ul class="footer_list">
          <li class="footer_list-item">
            <a href="#aboutSection" class="footer_list-link">About</a>
          </li>
          <li class="footer_list-item">
            <a href="#contactSection" class="footer_list-link">Contact</a>
          </li>
          <li class="footer_list-item">
            <a href="#selectedWorks" class="footer_list-link">Selected Works</a>
          </li>
        </ul>
      </div>
      <div>
        <h6 class="footer_title"></h6>
        <ul class="footer_list">
          <li class="footer_list-item">
            <a href="#" class="footer_list-link"></a>
          </li>
        </ul>
      </div>
      <div>
        <h6 class="footer_title"></h6>
        <ul class="footer_list">
          <li class="footer_list-item">
            <a href="#" class="footer_list-link"></a>
          </li>
        </ul>
      </div>
      <div>
        <h6 class="footer_title">Social Media</h6>
        <ul class="footer_list">
          <li class="footer_list-item">
            <a href="https://www.linkedin.com/in/sercan-naya/" target="_blank" class="footer_list-link">LinkedIn</a>
          </li>
          <li class="footer_list-item">
            <a href="https://github.com/sercannaya" target="_blank" class="footer_list-link">Github</a>
          </li>
          <li class="footer_list-item">
            <a href="http://linktree.sercannaya.com/" target="_blank" class="footer_list-link">Linktree</a>
          </li>
        </ul>
      </div>
    </div>
    <hr class="footer_divider">
    <div class="footer_bottom">
      <span class="copyright">©{{ currentYear }} Sercan NAYA. All Rights Resevered</span>
      <ul class="footer_list">
        <li class="footer_list">
<!--           <a href="/coffee" class="footer_list-link">
            <lord-icon
              src="https://cdn.lordicon.com/uxtcmjmu.json"
              trigger="hover"
              colors="primary:#ffffff,secondary:#ffffff"
              >
            </lord-icon>         
          </a> -->
          <a href="#" class="footer_list-link">
            <lord-icon
              src="https://cdn.lordicon.com/xsdtfyne.json"
              trigger="hover"
              colors="primary:#ffffff"
              state="hover-2"
            >
            </lord-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</footer>  
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  }
};
</script>

<style>
.footer {
  padding-block: 50px;
  background-color: #000;
  color: #CBB4D4;
  text-align: left;
}

.footer_container {
  max-width: 1160px;
  margin: auto;
  padding: 0 15px;
}

.footer_top {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  row-gap: 20px;
}

.footer_title {
  font-size: 20px;
  color: #FFF;
  font-weight: 500;
  margin-bottom: 30px;
}

.footer_list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer_list-item {
  list-style: none;
}

.footer_list-link {
  text-decoration: none;
  color: inherit;
  transition: color .25s;
}

.footer_list-link:hover {
  color: #FFF;
}

.footer_divider {
  margin-block: 25px;
  border: none;
  border-top: 1px solid #cecece;
}

.footer_bottom {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
}

.footer_bottom .footer_list {
  flex-direction: row;
}

.social-media-icon {
  height: 2em;
  color: #FFF;
  transition: all 0.3s;
}

.social-media-icon:hover {
  color: #CBB4D4;
}

lord-icon {
  width: 40px;
  height: 40px;
}


/* ===== Breakpoints ===== */

@media only screen and (min-width: 768px) and (max-width: 1024px) { 
  .footer_top {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}

@media only screen and (min-width: 2560px) and (max-width: 3839px) { 
 .footer {
  font-size: 25pt;

 }

 .footer_container {
    max-width: 2000px;
  }

  .footer_divider {
    margin-block: 50px;
    border-top: 3px solid #cecece;
  }

  .footer_title {
    font-size: 30pt;
  }

  lord-icon {
    width: 80px;
    height: 80px;
  }
}

@media only screen and (min-width: 3840px) { 
  .footer {
    font-size: 35pt;
   }
  
   .footer_container {
    max-width: 3000px;
  }

  .footer_divider {
    margin-block: 50px;
    border-top: 5px solid #cecece;
  }
  
  .footer_title {
    font-size: 45pt;
   }

  lord-icon {
    width: 120px;
    height: 120px;
  }
}

</style>