import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import AOS from 'aos'
import "aos/dist/aos.css";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
/* add icons to the library */
library.add(faLinkedinIn, faGithub, faYoutube, faInstagram)

createApp(App, AOS.init()).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
