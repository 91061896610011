<template>
  <section id="selectedWorks" class="section section--showcase">
    <header class="section__header">
      <h2 class="section__title section__title--medium">Selected Works</h2>
    </header>
    <div class="row"
      v-for="(selectedWorks) in selectedWorks" 
      :key="selectedWorks"
    >
      <div class="row__header">
        <span>{{selectedWorks.project_name}}</span>
        <span>{{selectedWorks.date}}</span>
        <span>{{selectedWorks.description}}</span>
      </div>
      <swiper
        :slidesPerView="1"
        :spaceBetween="10"
        :autoHeight="true"
        :loop= "true"
        :loopFillGroupWithBlank= "true"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: true,
        }"
        :preload="auto"
        :pagination="{
          clickable: true,
        }"
        :breakpoints="{
          '640': {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          '768': {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          '1024': {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }"
        :modules="modules"
        class="mySwiper noselect"
        data-aos="fade-down" data-aos-duration="1000"
        >
        <swiper-slide 
          v-for="img of selectedWorks.images" 
          :key="img"
        >
          <img :src="img.img" rel="preload">
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";

import agency_company_1 from '../assets/selected-works/agency-company/agency-company-1.webp'
import agency_company_2 from '../assets/selected-works/agency-company/agency-company-2.webp'
import agency_company_3 from '../assets/selected-works/agency-company/agency-company-3.webp'
import agency_company_4 from '../assets/selected-works/agency-company/agency-company-4.webp'

import florist_website_1 from '../assets/selected-works/florist-website/florist-website-1.webp'
import florist_website_2 from '../assets/selected-works/florist-website/florist-website-2.webp'
import florist_website_3 from '../assets/selected-works/florist-website/florist-website-3.webp'
import florist_website_4 from '../assets/selected-works/florist-website/florist-website-4.webp'

import altin_igne_mockup_1 from '../assets/selected-works/altin-igne/Mockup-1.webp'
import altin_igne_mockup_2 from '../assets/selected-works/altin-igne/Mockup-2.webp'
import altin_igne_web_design from '../assets/selected-works/altin-igne/web-design.webp'

import portfolio_website_1 from '../assets/selected-works/portfolio-website/portfolio-website-1.webp';
import portfolio_website_2 from '../assets/selected-works/portfolio-website/portfolio-website-2.webp';
import portfolio_website_3 from '../assets/selected-works/portfolio-website/portfolio-website-3.webp';
import portfolio_website_4 from '../assets/selected-works/portfolio-website/portfolio-website-4.webp';
import portfolio_website_5 from '../assets/selected-works/portfolio-website/portfolio-website-5.webp';
import portfolio_website_6 from '../assets/selected-works/portfolio-website/portfolio-website-6.webp';
import portfolio_website_7 from '../assets/selected-works/portfolio-website/portfolio-website-7.webp';

import beer_company_1 from '../assets/selected-works/beer-company/beer-company-1.webp';
import beer_company_2 from '../assets/selected-works/beer-company/beer-company-2.webp';

import pizza_company_1 from '../assets/selected-works/pizza-company/pizza-company-1.webp';
import pizza_company_2 from '../assets/selected-works/pizza-company/pizza-company-2.webp';

import construction_company_1 from '../assets/selected-works/construction-company/construction-company-1.webp';
import construction_company_2 from '../assets/selected-works/construction-company/construction-company-2.webp';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      selectedWorks: [
        {
          project_name: 'Naya Agency',
          date: '2023',
          description: 'Website Design & Development',
          images: [
            {
              img: agency_company_1,
              alt: 'sercan naya selected work - naya agency - 1'
            },
            {
              img: agency_company_2,
              alt: 'sercan naya selected work - naya agency - 2'
            },
            {
              img: agency_company_3,
              alt: 'sercan naya selected work - naya agency - 3'
            },
            {
              img: agency_company_4,
              alt: 'sercan naya selected work - naya agency - 3'
            },
          ],
        },
        {
          project_name: 'Naya Florist',
          date: '2023',
          description: 'Website Design & Development',
          images: [
            {
              img: florist_website_1,
              alt: 'sercan naya selected work - naya florist - 1'
            },
            {
              img: florist_website_2,
              alt: 'sercan naya selected work - naya florist - 2'
            },
            {
              img: florist_website_3,
              alt: 'sercan naya selected work - naya florist - 3'
            },
            {
              img: florist_website_4,
              alt: 'sercan naya selected work - naya florist - 3'
            },
          ],
        },
        {
          project_name: 'Golden Needle',
          date: '2022',
          description: 'Website Design & Development and Logo Design',
          images: [
            {
              img: altin_igne_mockup_1,
              alt: 'sercan naya selected work - golden needle - 1'
            },
            {
              img: altin_igne_mockup_2,
              alt: 'sercan naya selected work - golden needle - 2'
            },
            {
              img: altin_igne_web_design,
              alt: 'sercan naya selected work - golden needle - 3'
            },
          ],
        },
        {
          project_name: 'Beer Company',
          date: '2022',
          description: 'Website Design & Development',
          images: [
            {
              img: beer_company_2,
              alt: 'sercan naya selected work - beer company - 1'
            },
            {
              img: beer_company_1,
              alt: 'sercan naya selected work - beer company - 2'
            },
          ],
        },
        {
          project_name: 'Portfolio Website',
          date: '2021',
          description: 'Website Design & Development',
          images: [
            {
              img: portfolio_website_1,
              alt: 'sercan naya selected work - Portfolio Website - 2'
            },
            {
              img: portfolio_website_2,
              alt: 'sercan naya selected work - Portfolio Website - 3'
            },
            {
              img: portfolio_website_3,
              alt: 'sercan naya selected work - Portfolio Website - 4'
            },
            {
              img: portfolio_website_4,
              alt: 'sercan naya selected work - Portfolio Website - 5'
            },
            {
              img: portfolio_website_5,
              alt: 'sercan naya selected work - Portfolio Website - 6'
            },
            {
              img: portfolio_website_6,
              alt: 'sercan naya selected work - Portfolio Website - 7'
            },
            {
              img: portfolio_website_7,
              alt: 'sercan naya selected work - Portfolio Website - 8'
            },
          ],
        },
        {
          project_name: 'Pizza Company',
          date: '2021',
          description: 'Website Design & Development',
          images: [
            {
              img: pizza_company_1,
              alt: 'sercan naya selected work - pizza company - 1'
            },
            {
              img: pizza_company_2,
              alt: 'sercan naya selected work - pizza company - 2'
            }
          ],
        },
        {
          project_name: 'Construction Company',
          date: '2021',
          description: 'Website Design & Development',
          images: [
            {
              img: construction_company_1,
              alt: 'sercan naya selected work - construction company - 1'
            },
            {
              img: construction_company_2,
              alt: 'sercan naya selected work - construction company - 2'
            },
          ],
        },
      ],
    };
  },

  setup() {
    return {
      modules: [Pagination, Navigation, Autoplay],
    };
  },
};


</script>

<style>

:root {
	--color-text: #FFF;
	--color-bg: hsl(22deg 25% 73%);
	--color-text-alt: hsl(1deg 69% 50%);
	--color-link: #FFF;
	/* Column/item variables */
	--grid-item-width: 50vw;	/* image width */
	--justify: space-between;	/* spread columns or center */
	--gap: 0vw;				/* column gap and margin for items */
	--offset: -30vh;			/* offset of even columns */
	/* content section */
	--radius-small: 5px; 		/* border radius of small images */
}

.section {
	line-height: 1;
	position: static;
    top: 0;
    z-index: 4;
    width: 100%;
    min-height: 100vh;
}


.section__title {
	font-weight: bold;
	margin: 5vh 0 10vh;
	line-height: 1;
	text-transform: uppercase;
  color: #FFF;
}

.section__title--medium {
	font-size: clamp(1.5rem,12vw,9rem);
}

.section--showcase {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	justify-content: center;
	padding: 8rem 5vw 10vh;
}

.section__header {
	display: flex;
	border-bottom: 1px solid #FFF;
	width: 100%;
	align-items: center;
	justify-content: space-around;
	padding: 4vh 8vw;
	margin-bottom: 4rem;
}

.row {
	text-align: left;
	width: 100%;
}

.row img {
  filter: grayscale(100%);
  transition: 0.5s all;
}

.row img:hover {
  filter: grayscale(0%);
}

.row__header {
	width: 100%;
	display: grid;
	grid-template-columns: 4ch 1fr;
	grid-template-rows: auto auto;
	grid-gap: 1rem;
	justify-content: start;
	padding: 8rem 0 1em;
  color: #FFF;
}

.row__header span:last-child {
	grid-column: 1 / span 2;
}

.row + p {
	margin-top: 40vh;
}

/* Only Work Section Swiper */
@media screen and (max-width: 1023px) {
  .row img {
    filter: grayscale(0%);
  }
}

@media screen and (min-width: 53em) {
	.section, 
	.row {
		font-size: 1.45rem;
	}

	.row__header {
		display: grid;
		grid-template-columns: 13ch auto 1fr;
		grid-template-rows: auto;
	}

	.row__header span:last-child {
		justify-self: end;
		grid-column: revert;
	}
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(-20%);
  transition: all 1s;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff; 

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: var(--radius-small);
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.swiper-pagination > .swiper-pagination-bullet-active {
	background-color: #FFF !important; 
}

@media only screen and (min-width:320px) {
  .section__header {
    margin-bottom: 0rem;
  }

  .section__title {
    margin: 5vh 0 1vh;
  }

  .section__title--medium {
    font-size: clamp(1.5rem, 16vw, 9rem);
  }

  .row__header {
    display: inline-flex;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    justify-items: stretch;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
  }
}

@media only screen and (min-width: 375px) { 
  .section__title--medium {
    font-size: clamp(1.5rem, 16vw, 9rem);
  }
}

@media only screen and (min-width: 768px) {
  .row__header {
    display: inline-flex;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    justify-items: stretch;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: stretch;
  }
}

@media only screen and (min-width:961px) { 
  .row__header {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    align-items: baseline;
    justify-content: space-between;
    justify-items: start;
  }
}

@media only screen and (min-width: 2560px) {
  .section__header {
    margin-bottom: 0rem;
  }

  .section__title {
    margin: 10vh 0 5vh;
  }

  .section__title--medium {
    font-size: clamp(5rem, 20vw, 20rem);
  }
  
  .row__header {
    font-size: 3rem;
  }

  .swiper-slide img{
    width: 100%;
    height: 500px;
  }

  .swiper-pagination { 
    transform: scale(3)
     translateY(-20%);
   }
}

@media only screen and (min-width: 3840px) { 
  .section__header {
    margin-bottom: 0rem;
  }

  .section__title {
    margin: 10vh 0 5vh;
  }

  .section__title--medium {
    font-size: clamp(5rem, 20vw, 30rem);
  }
  
  .swiper {
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .row__header {
    font-size: 5rem;
  }

  .swiper-slide img {
    width: 100%;
    height: 700px;
  }

  .swiper-pagination { 
   transform: scale(4)
    translateY(-130%);
  }
}
</style>